'use client'

import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAnalyticsSetup } from '~/components/hooks/useAnalytics'
import { useFeatureFlags } from '~/components/providers/CommerceConfigurationProvider/CommerceConfigurationProvider'
import ProductBrowser from '~/components/shared/ProductBrowser/ProductBrowser'
import type { Analytics } from '~/lib/analytics'
import { pageHasFeaturedProducts } from '~/lib/client-server/cms/sources/coremedia/shared/featured-products'
import { pageHasHotspots } from '~/lib/client-server/cms/sources/coremedia/shared/hotspots'
import { combineSegmentParametersWithQueryParameters, type SearchProductsQuery } from '~/lib/products'
import type { CategoryProps } from '~/lib/types/pageprops.interface'
import { getPathSegmentsAfterDescriptors } from '~/lib/utils'
import { useProducts } from '~/components/providers/ProductProvider/ProductProvider'
import { useParams, usePathname } from 'next/navigation'
import { ProductBrowserSkeleton } from '~/components/shared/ProductBrowser/ProductBrowserSkeleton'
import { createClientLogger } from '~/lib/logger'
import { useSession } from '~/components/providers/UaSessionProvider/UaSessionProvider'
import { useLocale } from '~/components/hooks/useLocale'
import type { ClientProductList } from '~/lib/types/product.interface'
import type { BreadcrumbTrail } from '~/components/shared/Breadcrumbs'
import ContentSlot from '~/components/cms/ContentSlot/ContentSlot'
import { getCategoryBanner } from '~/lib/content'
import { forceNumber } from '~/types/strict-null-helpers'
import { pageHasContentCarousel, pageHasStoryPlayer } from '~/lib/client-server/cms/sources/coremedia/shared/collection'
import { ContentSource } from '~/lib/client-server/cms/types'

const logger = createClientLogger('category-products')

export function CategoryProducts({
	cachedData,
	options,
	productList,
	breadcrumbs,
	searchRefinementInput,
	category,
	pageContent,
}: CategoryProps) {
	const { user } = useSession()
	const params = useParams()
	const path = usePathname()
	const locale = useLocale()

	const [products, setProducts] = useState<ClientProductList | null>(null)
	const [crumbs, setCrumbs] = useState<BreadcrumbTrail | null>(breadcrumbs || [])

	/**
	 * When the page is statically built, certain parameters are embedded in the URL.  These parameters include things like
	 * the offset, the sort rule, and things like view preferences, filters, etc.  In this case we will extract the search parameters
	 * from the URL and use them to build the search query.
	 */
	const searchProductsQuery = useMemo(() => {
		return combineSegmentParametersWithQueryParameters(params)
	}, [params])

	const productManager = useProducts({
		locale,
		user,
		allRefinements: options?.refinements || [],
		sortOptions: options?.sorting || [],
	})

	const { isQuickAddToCartEnabled } = useFeatureFlags() || {}
	const urlSegments = getPathSegmentsAfterDescriptors(path)
	const offset = searchProductsQuery.start || 0
	const hasHotspots = pageHasHotspots(pageContent)
	const hasFeaturedProducts = useMemo(() => pageHasFeaturedProducts(pageContent), [pageContent]) // Memoized for savings from looping in pageHasFeaturedProducts
	const hasContentCarousel = pageHasContentCarousel(pageContent)
	const hasStoryPlayer = pageHasStoryPlayer(pageContent)

	const beforePageView = useCallback(
		(analytics: Analytics) => {
			const gridData = productList ? analytics.getProductGridData(productList.products) : []
			const refinementAttributes = breadcrumbs ? analytics.getRefinementsData(breadcrumbs) : []
			const totalCount = productList?.totalCount || 0
			const categoryId = category?.id

			analytics.setPageData({
				page_name: urlSegments.join('|'),
				page_category: urlSegments[0] || undefined,
				page_category_id: categoryId || undefined,
				page_subcategory1: urlSegments[1] || undefined,
				page_subcategory2: urlSegments[2] || undefined,
				page_subcategory3: urlSegments[3] || undefined,
				page_subcategory4: urlSegments[4] || undefined,
				products: gridData,
				grid_has_guidedselling: false,
				grid_paging_offset: forceNumber(offset, 0),
				grid_single_ingrid: 0,
				grid_stack_count: 0,
				grid_double_ingrid: 0,
				grid_video_count: 0,
				grid_top_content: '',
				grid_has_loadmore: productList ? totalCount > productList.products.length : false,
				grid_sort_order: searchProductsQuery.srule,
				grid_total_count: totalCount,
				grid_visible_count: totalCount,
				grid_refinement_attributes: refinementAttributes,
				page_finding_method: '',
				features: [isQuickAddToCartEnabled && 'quick_atb'].filter((n) => n).join(','),
				featured_products: {
					state: hasFeaturedProducts ? 'viewed' : null,
				},
				hotspots: {
					state: hasHotspots ? 'viewed' : null,
				},
				content_carousel: {
					state: hasContentCarousel ? 'loaded' : null,
				},
				storyplayer: {
					state: hasStoryPlayer ? 'loaded' : null,
				},
			})
		},
		[
			productList,
			breadcrumbs,
			category?.id,
			urlSegments,
			offset,
			searchProductsQuery.srule,
			isQuickAddToCartEnabled,
			hasFeaturedProducts,
			hasHotspots,
			hasContentCarousel,
			hasStoryPlayer,
		],
	)

	useAnalyticsSetup(
		{
			allowPageView: true,
			page_name: '', // this has to get set dynamically
			pageCategory: [], // this has to get set dynamically
			page_type: 'product-listing',
			site_section: 'Product Refinement',
			beforePageView,
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[productList, breadcrumbs, searchProductsQuery, urlSegments],
	)

	// This method is used for update products on user change and also refinements for mobile filter sidebar
	const getProductsRequest = useCallback(
		(searchContext: SearchProductsQuery) => {
			if (category) {
				return productManager.getProductsByCategory(category, searchContext)
			}
			return Promise.reject(new Error('No category'))
		},
		[category, productManager],
	)

	// This will reload the category products when the category or search query changes.
	//  Note that it will always run once when the page first loads.  This is because we
	//  want it to query when there is user information available - something that is not available
	//  when the page is built on the server.
	useEffect(
		function updateResultsIfCategoryOrUserChanges() {
			getProductsRequest(searchProductsQuery)
				.then((data) => {
					if (data) {
						setProducts(data.products)
						setCrumbs(data.breadcrumbs)
					}
				})
				.catch((err) => {
					logger.error('Error getting products by category', err)
				})
		},
		[user?.session.uacapi.accessToken, getProductsRequest, searchProductsQuery],
	)

	// checks if category banner hero is set within CM
	const categoryBanner = getCategoryBanner(pageContent)

	const productLists = useMemo(
		() =>
			products ??
			productList ?? {
				paginationInfo: {},
				products: [],
				refinementAttributes: [],
				sortingOptions: [],
				totalCount: 0,
			},
		[productList, products],
	)

	// If we have any product data, render the browser. The "isLoadingClient" variable
	// will load server-generated product data in the browser but style it as a loading
	// product grid until the client-side request is completed. This removes a flicker
	// on PLP from server-content being quickly replaced by personalized client-side data.
	if (products || productList) {
		return (
			<>
				{categoryBanner && (
					<ContentSlot
						placementId={'category-hero'}
						defaultModule={{ source: ContentSource.COREMEDIA, data: categoryBanner }}
						locale={locale}
						index={0}
					/>
				)}
				<ProductBrowser
					cachedData={cachedData}
					isLoadingClient={!products}
					productList={productLists}
					category={category}
					pageContent={pageContent}
					breadcrumbs={crumbs || []}
					getProductsRequest={getProductsRequest}
					searchRefinementInput={searchRefinementInput}
					allRefinements={options?.refinements || []}
					sortOptions={options?.sorting || []}
					pageQueryParameters={searchProductsQuery}
					pageType={'ProductListingPage'}
				/>
			</>
		)
	}

	logger.error('No products found for category', category?.id)
	return <ProductBrowserSkeleton isSearch={false} />
}
